import {
  Aoi,
  DefaultSources,
  ITiff,
  ModelId,
  ModelOptionId,
  Processing,
  Schema,
  SourcePayload,
  TDataInputType,
} from "types";

import { wizardDomain } from "../root";

const { createEvent } = wizardDomain;

export type TCopyConfigParams = Processing["config"] & { name: string };

const setInputType = createEvent<TDataInputType>();

const setAOI = createEvent<Aoi>();
const setSource = createEvent<SourcePayload>();
const setModel = createEvent<ModelId>();
const setSelectedProcessing = createEvent<Processing>();
const setProcessingName = createEvent<string>();
const setSchema = createEvent<Schema>();
const setDefaultSources = createEvent<DefaultSources>();

const setRasterFile = createEvent<ITiff>();

// user event
const copyProcessingConfig = createEvent<TCopyConfigParams>();
// models event
const copyProcessingForRaster = createEvent<TCopyConfigParams>();
const copyProcessingForDrawing = createEvent<TCopyConfigParams>();

const toggleModelOption = createEvent<ModelOptionId>();
const setSingleModelOption = createEvent<ModelOptionId>();

const clearAOI = createEvent();
const clearSelectedProcessing = createEvent();
const clearWizard = createEvent();
const clearSource = createEvent();

export default {
  setAOI,
  setSource,
  setDefaultSources,
  setModel,
  setSelectedProcessing,
  setProcessingName,
  setSchema,
  toggleModelOption,
  setSingleModelOption,
  copyProcessingConfig,
  copyProcessingForRaster,
  copyProcessingForDrawing,
  clearAOI,
  clearSource,
  clearSelectedProcessing,
  clearWizard,
  setRasterFile,
  setInputType,
};
