import { persist } from "effector-storage/query";

import { setPayload } from "shared/lib/effector/helpers";

import { debounce } from "patronum";

import root from "./root";
import { historyChanged } from "./router";

const searchBarDomain = root.createDomain("search-bar");

const { createEvent, createStore } = searchBarDomain;

export const searchChanged = createEvent<string>();
export const clearClicked = createEvent();

export const $search = createStore<string>("");
export const $searchDebaunced = createStore<string>("");

$search.on(searchChanged, setPayload).reset([clearClicked, historyChanged]);

debounce({
  source: $search,
  timeout: 500,
  target: $searchDebaunced,
});

persist({
  key: "search",
  store: $searchDebaunced.map((v) => (v === "" ? null : v)), // for remove empty query string
});
