/**
 * Processings
 */

import { Aoi, MaybeNull, SourcePayload, UrlType } from "types";
import { ITiff, ModelId, ModelOptionId } from "types/model";

import { CrsValue } from "./wizard";

export interface IProcessingAPI {
  getProcessings(projectId: string): Promise<Processing[]>;

  createProcessing(payload: ProcessingBackend): Promise<Processing>;

  updateProcessing(id: string, payload: ProcessingUpdate): Promise<Processing>;
}

export enum ProcessingStatus {
  PENDING = <any>"PENDING",
  INPROGRESS = <any>"IN_PROGRESS",
  OK = <any>"OK",
  FAILED = <any>"FAILED",
}

export type ProcessingCost = { cost: number };

export enum StageName {
  "dataloader" = "dataloader",
  "validate-source" = "validate-source",
  "inference" = "inference",
  "build-cog" = "build-cog",
  "select-source" = "select-source",
  "import-vector" = "import-vector",
  "user-input" = "user-input",
}

export type Stage = {
  name: StageName;
  description: string;
  status: ProcessingStatus;
};

export interface ProcessingPayload {
  name: string;
  description?: string;
  projectId: string;
  source: SourcePayload;
  aoi: Aoi;
  model: ModelId;
  modelOptionsIds: ModelOptionId[];
  // TODO
  raster?: ITiff | null;
}

export type TProcessingMessage = {
  code: string;
  parameters: Record<string, string | number | boolean>;
};

export interface ProcessingBackendRaw {
  id: string;
  cost: number;
  created: string;
  stars: ProcessingRating;
  projectId: string;
  name: string;
  description?: string;
  workflow: {
    id: number;
    status: ProcessingStatus;
    stages: Stage[];
    vectorLayer: {
      layerId: string;
      uri: string;
    };
    rasterLayer: {
      uri?: string;
    };
  };
  config: {
    rasterSource: {
      type: UrlType.XYZ | "tif";
      url: string;
      yOriginTop: boolean;
      zoom: number;
      crs: CrsValue;
    };
    aoi: {
      geometry: Aoi;
    };
    modelId: ModelId;
    optionIds: ModelOptionId[];
  };
  messages?: TProcessingMessage[];
}

type StaticProperties = "workflow" | "stars" | "cost" | "created" | "id";

export type ProcessingBackend = Omit<ProcessingBackendRaw, StaticProperties>;

export type ProcessingConfig = Processing["config"];

export type ProcessingRating = 0 | 1 | 2 | 3 | 4 | 5;

export interface Processing {
  id: string;
  projectId: string;
  name: string;
  description: MaybeNull<string>;
  created: string;
  config: {
    source: SourcePayload;
    aoi: Aoi;
    modelId: ModelId;
    modelOptionIds: ModelOptionId[];
  };
  vectorLayer: string;
  rasterLayer?: string;
  status: ProcessingStatus;
  cost: number;
  progress: {
    stages: Stage[];
  };
  stars: ProcessingRating;
  messages: TProcessingMessage[];
}

export type ProcessingUpdate = Partial<
  Pick<Processing, "name" | "description">
>;
