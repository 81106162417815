export enum SortBy {
  name = "name",
  created = "created",
  updated = "updated",
}

export enum SortOrder {
  asc = "asc",
  desc = "desc",
}
