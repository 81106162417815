import { combine } from "effector";

import { Geometry } from "@turf/helpers";

import { calculateArea } from "shared/lib/geo/calculate-area";
import { tryTransform } from "shared/lib/try-transform";

import {
  Aoi,
  DefaultSources,
  ITiff,
  MaybeNull,
  ModelId,
  ModelOptionId,
  Processing,
  Schema,
  SourcePayload,
  TDataInputType,
} from "types";

import { wizardDomain } from "../root";
import { fallbackModels, reduceModelsOption } from "./helpers";

const { createStore } = wizardDomain;

const $inputType = createStore<TDataInputType>("drawing");

const $aoi = createStore<MaybeNull<Aoi>>(null); // TODO: IS FEATURE!! CONVERT IN FEATURE OR GEOMETRY
const $source = createStore<MaybeNull<SourcePayload>>(null);
const $modelId = createStore<MaybeNull<ModelId>>(null);
const $selectedProcessing = createStore<MaybeNull<Processing>>(null);
const $schema = createStore<MaybeNull<Schema>>(null);

// Global flag for start processing, not including ADMIN check
const $isDisabled = createStore(true); // used for hidden button (start processing) also

const $notAllowedOptions = createStore<ModelOptionId[]>([]);

//for raster
const $rasterFile = createStore<MaybeNull<ITiff>>(null, {
  serialize: "ignore",
});

// used in when AOI more then raster.aoi
const $usefulAOI = createStore<MaybeNull<Geometry>>(null);

const $defaultSources = createStore<MaybeNull<DefaultSources>>(null);

const $modelOptionsIds = createStore<ModelOptionId[]>([]);
const $processingName = createStore<string>("");

// COMPUTED STORES
const $area = $aoi.map(tryTransform(calculateArea, 0));

const $usefulArea = $usefulAOI.map(tryTransform(calculateArea, 0));

const $modelByOptions = $schema.map(
  tryTransform(reduceModelsOption, fallbackModels)
);

const $isSAMSelected = $modelId.map((modelId) => modelId === ModelId.SAM);

const $isSourceAs = combine($source, $defaultSources, (source, defaults) => {
  if (source?.url === defaults?.mapbox?.url) return "mapbox";
  if (source?.url === defaults?.arcgis?.url) return "arcgis";
  return "custom";
});

const $isDefaultSource = $isSourceAs.map((is) =>
  ["mapbox", "arcgis"].includes(is)
);

const $form = combine({
  aoi: $aoi,
  source: $source,
  modelId: $modelId,
  modelOptionsIds: $modelOptionsIds,
  processingName: $processingName,
});

export default {
  $aoi,
  $source,
  $isSourceAs,
  $modelId,
  $selectedProcessing,
  $schema,
  $defaultSources,
  $isDefaultSource,
  $modelOptionsIds,
  $modelByOptions,
  $notAllowedOptions,
  $processingName,
  $area,
  $rasterFile,
  $form,
  $inputType,
  $isDisabled,
  $usefulAOI,
  $usefulArea,
  $isSAMSelected,
};
