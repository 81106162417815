import { Model, ModelId, ModelOptionId } from "types";

// TODO need move it on backend
// All Ranges should be specified required and recommended
{
  /* TODO: re-check zoom params */
}
export const ModelsZoomDefinition: Record<ModelId, Model["_zoom"]> = {
  [ModelId.Buildings]: {
    required: { min: 17 },
    recommended: { min: 18, max: 18 },
  },
  [ModelId.UavBuildings]: {
    required: { min: 19 },
    recommended: { min: 20, max: 21 },
  },
  [ModelId.DenseBuildings]: {
    required: { min: 18, max: 19 },
    recommended: { min: 18, max: 19 },
  },
  [ModelId.Constructions]: {
    required: { min: 17 },
    recommended: { min: 18, max: 18 },
  },
  [ModelId.Fields]: {
    required: { min: 17 },
    recommended: { min: 18, max: 18 },
  },
  [ModelId.Forest]: {
    required: { min: 17 },
    recommended: { min: 18, max: 18 },
  },
  [ModelId.Roads]: {
    required: { min: 17 },
    recommended: { min: 18, max: 18 },
  },
  [ModelId.SAM]: {
    required: { min: 14 },
    recommended: { min: 14, max: 20 },
  },
};

export const ZoomByModelOptions: Partial<Record<ModelOptionId, number>> = {
  sam14: 14,
  sam16: 16,
  sam18: 18,
  samAero: 20,
};
