import axios from "axios";

import { ValidationSourceResult } from "pages/api/validatesource";

import {
  ApiLimits,
  BaseProject,
  BillingRecord,
  DefaultSources,
  DisplayModelType,
  Maybe,
  PaginatedData,
  Processing,
  ProcessingPayload,
  ProcessingRating,
  ProcessingUpdate,
  Project,
  Schema,
  SortBy,
  SortOrder,
  TeamMember,
  TeamRoles,
  TeamStatus,
  TMUserProfile,
  TopUpPayload,
  TopUpUrl,
  UserInfo,
} from "types";

import { stringify } from "query-string";

export async function fetcher<T = any>(url: string): Promise<T> {
  return axios.get(url).then((res) => res.data);
}

/* USER SETTINGS */

export const updateUserMetaData = (data: TMUserProfile) => {
  return axios.put("/api/user/update", data);
};

export const fetchProfile = () => fetcher<TMUserProfile>("/api/user/profile");

export const fetchTeamStatus = () => fetcher<TeamStatus>("/api/user/status");

export const fetchTeamMembers = (teamId: string | undefined) =>
  fetcher<TeamMember[]>(`/api/team/members?teamId=${teamId}`);

export type AddTeamMemberPayload = {
  email: string;
  teamId: string;
  activeUntil?: string | null;
  role?: TeamRoles;
};
export const addTeamMember = (payload: AddTeamMemberPayload) => {
  return axios.post("/api/team/add-user", payload);
};

export type RemoveTeamMemberPayload = {
  email: string;
  userId: string;
  teamId: string;
};
export const removeTeamMember = (payload: RemoveTeamMemberPayload) => {
  return axios.post("/api/team/remove-user", payload);
};

export type UpdateTeamMemberPayload = {
  teamId: string;
  email: string;
  creditsLimit?: number;
  /**
   * in square meters
   */
  areaLimit?: number;
  /**
   * date string
   */
  activeUntil?: string;
};
export const updateTeamMember = (payload: UpdateTeamMemberPayload) => {
  return axios.put("/api/team/update-user", payload);
};

export const fetchUserInfo = () => fetcher<UserInfo>("/api/user-info");

type SchemaFilters = {
  models?: DisplayModelType;
};

export const fetchSchema = (filters: SchemaFilters = {}) => {
  // @ts-expect-error
  const qs = new URLSearchParams(filters).toString();

  return fetcher<Schema>(`/api/schema?${qs}`);
};

export const fetchDefaultSources = () =>
  fetcher<DefaultSources>("/api/default-sources");

export const fetchApiStats = () => fetcher<ApiLimits>("/api/apistats");
export const fetchApiToken = () => fetcher<string>("/api/getapitoken");

type TBillingReportParams = {
  start_date?: string;
  end_date?: string;
};

export const fetchBillingReport = (qs: Maybe<TBillingReportParams>) => {
  return axios.get<BillingRecord[]>(
    `/api/user/billing?${qs ? stringify(qs) : ""}`
  );
};

export const getPaymentUrl = (payload: TopUpPayload) => {
  return axios.post<TopUpUrl>(`/api/topup`, payload);
};

export const fetchTileURLFromCOG = async (cogURL: string): Promise<string> => {
  const { tiles } = await fetcher<{ tiles: string[] }>(cogURL);
  return tiles[0];
};

/* PROJECTS */
type TPaginatedProjectQS = {
  offset?: number;
  limit?: number;
  filter?: string;
  sortBy?: SortBy;
  sortOrder?: SortOrder;
};

export const fetchProjects = (qs: TPaginatedProjectQS = {}) =>
  fetcher<PaginatedData<Project>>(`/api/projects?${stringify(qs)}`);

export const fetchProjectById = (id: string) =>
  fetcher<Project>(`/api/projects/${id}`);

export const deleteProject = (id: string) => {
  return axios.delete(`/api/projects/${id}`);
};

export const updateProject = (id: string, data: BaseProject) => {
  return axios.put<Project>(`/api/projects/${id}`, data);
};

export const createProject = (data: BaseProject) => {
  return axios.post<Project>("/api/projects", data);
};

/* PROCESSING */

export const fetchProcessingsById = (id: string) =>
  fetcher<Processing[]>(`/api/projects/${id}/processings`);

export function createProcessingWithRaster(data: ProcessingPayload) {
  const formData = new FormData();
  const { raster, ...payload } = data;
  if (!raster) throw Error("Raster not found"); // for typescript

  Object.keys(payload).forEach((key) => {
    const data = payload[key as keyof typeof payload];
    const value = typeof data === "string" ? data : JSON.stringify(data);
    formData.append(key, value);
  });

  if (raster.file) {
    formData.append("raster.file", raster.file);
  }

  formData.append("file.type", raster.type);

  return axios.post<Processing>(`/api/processings/raster`, formData);
}

export function createProcessing(payload: ProcessingPayload) {
  if (payload.raster) return createProcessingWithRaster(payload);
  return axios.post<Processing>(`/api/processings`, payload);
}

export function calculateProcessingCost(data: Omit<ProcessingPayload, "name">) {
  return axios.post(`/api/processings/cost`, data);
}

export function rateProcessing(id: string, rating: number) {
  return axios.post<ProcessingRating>(`/api/processings/${id}/rate`, {
    rating,
  });
}

export function updateProcessing(id: string, data: ProcessingUpdate) {
  return axios.put<Processing>(`/api/processings/${id}`, data);
}

export function restartProcessing(id: string) {
  return axios.post<number>(`/api/processings/${id}/restart`);
}

export function deleteProcessing(id: string) {
  return axios.delete(`/api/processings/${id}`);
}

export function sendEmail(userId: string) {
  return axios.post<{ status: "pending" }>("/api/auth/send-email", {
    userId,
  });
}

/* OTHER */

export function validateSource(data: ProcessingPayload) {
  return axios.post<ValidationSourceResult>("/api/validatesource", data);
}

export type TGetAllowedZoom = Pick<ProcessingPayload, "aoi" | "source"> &
  Pick<Schema, "maxZoomOfAll" | "minZoomOfAll">;

export async function getAllowedZoom(params: TGetAllowedZoom) {
  const { data } = await axios.post<Record<number, boolean>>(
    "/api/zoom",
    params
  );
  return data;
}
